<template>
    <div>
        <TableList
            :columns="columns"
            :form_data_seo="form_data_seo"
            :get_table_list="this.get_table_list"
            :slot_table_list="['operation','status']"
            :is_page="false"
            rowKey="id"
            ref="list"
        >
            <template slot="left_btn">
                <a-button class="float_left" @click="getAddSave('','add')" type="primary">添加分类</a-button>
            </template>
            <template slot="status" slot-scope="data">
                <span v-if="data.record.status == 1" class="status_box" @click="getMaterialStatus(data.record)">启用</span>
                <span v-else class="status_box" style="background:red;" @click="getMaterialStatus(data.record)">禁用</span>
            </template>
            <template slot="operation" slot-scope="data">
                <a @click="getAddSave(data.record,'edit')">编辑</a>
                <a-divider type="vertical" />
                <a @click="getDelete(data.record)">删除</a>
            </template>
        </TableList>

        <!-- 添加或编辑指标分类 -->
        <a-modal v-model:visible="classifyShow" :title="classifyFrom.id ? '编辑指标分类信息' : '新增指标分类信息'" @ok="classifyOk">
            <classifyAddSaveVue :classifyListData="classifyListData" :materialList="materialList" :classifyFromData="classifyFrom" ref="classifyFrom" ></classifyAddSaveVue>
        </a-modal>
    </div>
</template>
<script>
import TableList from "@/components/TableList";
import {message,Modal} from 'ant-design-vue';
import classifyAddSaveVue from '../components/classifyAddSave.vue'
import {getQuotaCateList,saveQuotaCate,changeCateStatus,delQuotaCate,getMaterialListNoPage} from '@/api/qms.js'

const columns = [
    {
        title: "分类名称",
        dataIndex: "title",
    },
    
    {
        title: "所属产品",
        dataIndex: "goods_material",
    },
    {
        title: "状态",
        dataIndex: "status",
        scopedSlots: {
            customRender: "status"
        }
    },
    {
        title: "操作",
        scopedSlots: {
            customRender: "operation"
        }
    }
];

export default {
    components: {
        TableList,
        classifyAddSaveVue
    },
    data() {
        return {
            get_table_list: getQuotaCateList,
            columns,
            form_data_seo: {
                ...this.$config.form_data_seo,
                list: [
                    {
                        type: "text",
                        name: "keyword",
                        title: "关键词",
                        placeholder: "请输入指标分类名称",
                        options: {}
                    },
                ],
            },
            listData:[],
            classifyShow:false,
            classifyFrom:{
                id:'',
                name:'',
                pid:'',
                goods_related:''
            },
            materialList:[],
            classifyListData:[],
        };
    },
    created() {
        this.getMaterialList()
        this.getQuotaCateList()
    },
    methods: {
        getQuotaCateList(){
            getQuotaCateList({}).then(res=>{
                this.classifyListData = res.data.list
            })
        },

        getMaterialList(){
            getMaterialListNoPage({
                is_page:-1, //是否分页 1是-1否
                is_select:1 //是否选择框信息 1是-1否
            }).then(res=>{
                this.materialList = res.data.list
            })
        },

        getAddSave(info,type){
            //新增
            if(type == 'add'){
                this.classifyFrom = {
                    pid:[],
                    name:'',
                    goods_related:''
                }
                this.classifyShow = true
            }
            //编辑数据
            if(type == 'edit'){
                this.classifyFrom = {
                    id:info.id,
                    pid:info.pid > 0 ? info.pid : [],
                    name:info.title,
                    goods_related:info.goods_related
                }
                this.classifyShow = true
            }
        },

        classifyOk(){
            this.$refs.classifyFrom.getSubmit().then(res=>{
                saveQuotaCate({
                    ...res
                }).then(res=>{
                    this.classifyShow = false
                    message.success('保存成功');
                    this.$refs.list.get_list()
                })
            })
        },

        getDelete(info){
            let that = this
            Modal.confirm({
                title: '温馨提示',
                content: '确定要删除指标分类【'+info.title+'】吗？',
                okText: '确定',
                cancelText: '取消',
                onOk() {
                    delQuotaCate({
                        id:info.id
                    }).then(res=>{
                        message.success('删除成功');
                        that.$refs.list.get_list()
                    })
                }
            });
        },

        getMaterialStatus(info){
            let text = info.status == 1 ? "禁用" : "启用"
            let that = this
            Modal.confirm({
                title: '温馨提示',
                content: '确定要'+text+'指标分类【'+info.title+'】吗？',
                okText: '确定',
                cancelText: '取消',
                onOk() {
                    changeCateStatus({
                        id:info.id
                    }).then(res=>{
                        message.success('操作成功');
                        that.$refs.list.get_list()
                    })
                }
            });
        },

    }
};
</script>

<style lang="less" scoped>
.status_box{
    background:#1890ff;
    padding:1px 5px 3px 5px;
    color:#ffffff;
    border-radius:2px;
    font-size:12px;
    cursor:pointer;
    display:inline-block;
    min-width:34px;
}
</style>